import React, {Suspense, useEffect} from 'react';
import {BrowserRouter, Switch, Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Loader from '../../common/Loader/Loader';
import * as authService from '../../store/auth/service';
import {StoreState} from '../StoreProvider/StoreProvider';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import * as languageService from '../../store/language/service';
import * as userService from '../../store/user/service';
import {Language} from '../../domain/Language';
import {IntlProvider} from 'react-intl';
import {routes} from './routes';

const Login = React.lazy(() => import('../../pages/Public/Login/Login'));
const Registration = React.lazy(() => import('../../pages/Public/Registration/Registration'));
const PasswordRemind = React.lazy(() => import('../../pages/Public/PasswordRemind/PasswordRemind'));
const PasswordReset = React.lazy(() => import('../../pages/Public/PasswordReset/PasswordReset'));
const RegistrationConfirmation =
  React.lazy(() => import('../../pages/Public/RegistrationConfirmation/RegistrationConfirmation'));

const Profile = React.lazy(() => import('../../pages/Public/Profile/Profile'));

const Reservations = React.lazy(() => import('../../pages/Admin/Reservations/Reservations'));
const Locations = React.lazy(() => import('../../pages/Admin/Locations/Locations'));
const LocationCreate = React.lazy(() => import('../../pages/Admin/Locations/LocationCreate/LocationCreate'));
const LocationEdit = React.lazy(() => import('../../pages/Admin/Locations/LocationEdit/LocationEdit'));
const Items = React.lazy(() => import('../../pages/Admin/Items/Items'));
const ItemCreate = React.lazy(() => import('../../pages/Admin/Items/ItemCreate/ItemCreate'));
const ItemEdit = React.lazy(() => import('../../pages/Admin/Items/ItemEdit/ItemEdit'));
const Users = React.lazy(() => import('../../pages/Admin/Users/Users'));
const UserKeyEdit = React.lazy(() => import('../../pages/Admin/Users/UserKeyEdit/UserKeyEdit'));
const Orders = React.lazy(() => import('../../pages/Admin/Orders/Orders'));
const Categories = React.lazy(() => import('../../pages/Admin/Categories/Categories'));
const CategoryCreate = React.lazy(() => import('../../pages/Admin/Categories/CategoryCreate/CategoryCreate'));
const CategoryEdit = React.lazy(() => import('../../pages/Admin/Categories/CategoryEdit/CategoryEdit'));
const NewsPosts = React.lazy(() => import('../../pages/Admin/NewsPost/NewsPostListPage/NewsPostListPage'));
const NewsPostCreate = React.lazy(() => import('../../pages/Admin/NewsPost/NewsPostCreatePage/NewsPostCreatePage'));
const NewsPostEdit = React.lazy(() => import('../../pages/Admin/NewsPost/NewsPostEditPage/NewsPostEditPage'));

const Homepage = React.lazy(() => import('../../pages/Public/Homepage/Homepage'));
const AboutUs = React.lazy(() => import('../../pages/Public/AboutUs/AboutUs'));
const Events = React.lazy(() => import('../../pages/Public/Events/Events'));
const Shop = React.lazy(() => import('../../pages/Public/Shop/Shop'));
const ShopItem = React.lazy(() => import('../../pages/Public/ShopItem/ShopItem'));
const Cart = React.lazy(() => import('../../pages/Public/Cart/CartPage'));
const Checkout = React.lazy(() => import('../../pages/Public/Checkout/CheckoutPage'));
const Contacts = React.lazy(() => import('../../pages/Public/Contacts/Contacts'));
const Reservation = React.lazy(() => import('../../pages/Public/Reservation/Reservation'));
const PrivacyPolicy = React.lazy(() => import('../../pages/Public/PrivacyPolicy/PrivacyPolicy'));
const PurchasePolicy = React.lazy(() => import('../../pages/Public/PurchasePolicy/PurchasePolicy'));
const RefundPolicy = React.lazy(() => import('../../pages/Public/RefundPolicy/RefundPolicy'));
const News = React.lazy(() => import('../../pages/Public/News/News'));
const Article = React.lazy(() => import('../../pages/Public/Article/Article'));
const Event = React.lazy(() => import('../../pages/Public/Event/Event'));
const Coaches = React.lazy(() => import('../../pages/Public/Coaches/CoachesPage'));

export type Props = {
  isAuthenticated: boolean,
  isInitCompleted: boolean,
  onTryAutoSignup: () => void,
  languageLoading: boolean,
  onLanguageInit: (locale: string) => void,
  language: Language | null,
  isCurrentUserLoading: boolean,
  onFetchCurrentUser: () => void,
};

export const Router = ({
  isAuthenticated,
  isInitCompleted,
  onTryAutoSignup,
  onLanguageInit,
  language,
  isCurrentUserLoading,
  onFetchCurrentUser,
}: Props) => {
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  useEffect(() => {
    onLanguageInit('lt');
  }, [onLanguageInit]);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  const mappedTranslations = language?.translations.reduce((obj, item) =>
    Object.assign(obj, {
      [item.alias]: item.value ? item.value : item.defaultValue,
    }), {});

  return (
    <BrowserRouter basename='/'>
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? 'lt'}
          defaultLocale="lt"
        >
          <Suspense fallback={<Loader isLoading isFullScreen />}>
            {isAuthenticated ? (
              <Switch>
                <Route path={routes.homepage} exact component={Homepage} />
                <Route path={routes.aboutUs} exact component={AboutUs} />
                <Route path={routes.events} exact component={Events} />
                <Route path={routes.event} exact component={Event} />
                <Route path={routes.shop} exact component={Shop} />
                <Route path={routes.shopItem} exact component={ShopItem} />
                <Route path={routes.cart} exact component={Cart} />
                <Route path={routes.checkout} exact component={Checkout} />
                <Route path={routes.contact} exact component={Contacts} />
                <Route path={routes.reservation} exact component={Reservation} />
                <Route path={routes.termsAndConditions} exact component={PurchasePolicy} />
                <Route path={routes.refundPolicy} exact component={RefundPolicy} />
                <Route path={routes.privacyPolicy} exact component={PrivacyPolicy} />
                <Route path={routes.newsArticle} exact component={Article} />
                <Route path={routes.news} exact component={News} />
                <Route path={routes.profile} exact component={Profile} />
                <Route path={routes.coaches} exact component={Coaches} />
                <Route path={routes.adminReservations} exact component={Reservations} />
                <Route path={routes.adminLocationCreate} exact component={LocationCreate} />
                <Route path={routes.adminLocationEdit} exact component={LocationEdit} />
                <Route path={routes.adminLocations} exact component={Locations} />
                <Route path={routes.adminItemCreate} exact component={ItemCreate} />
                <Route path={routes.adminItemEdit} exact component={ItemEdit} />
                <Route path={routes.adminItems} exact component={Items} />
                <Route path={routes.adminCategoryCreate} exact component={CategoryCreate} />
                <Route path={routes.adminCategoryEdit} exact component={CategoryEdit} />
                <Route path={routes.adminCategories} exact component={Categories} />
                <Route path={routes.adminNewsCreate} exact component={NewsPostCreate} />
                <Route path={routes.adminNewsEdit} exact component={NewsPostEdit} />
                <Route path={routes.adminNews} exact component={NewsPosts} />
                <Route path={routes.adminUsersEditLock} exact component={UserKeyEdit} />
                <Route path={routes.adminUsers} exact component={Users} />
                <Route path={routes.adminOrders} exact component={Orders} />
                <Redirect to={isAuthenticated ? routes.profile : routes.homepage} />
              </Switch>
            ) : (
              <Switch>
                <Route path={routes.homepage} exact component={Homepage} />
                <Route path={routes.aboutUs} exact component={AboutUs} />
                <Route path={routes.events} exact component={Events} />
                <Route path={routes.event} exact component={Event} />
                <Route path={routes.shop} exact component={Shop} />
                <Route path={routes.shopItem} exact component={ShopItem} />
                <Route path={routes.cart} exact component={Cart} />
                <Route path={routes.checkout} exact component={Checkout} />
                <Route path={routes.contact} exact component={Contacts} />
                <Route path={routes.reservation} exact component={Reservation} />
                <Route path={routes.termsAndConditions} exact component={PurchasePolicy} />
                <Route path={routes.refundPolicy} exact component={RefundPolicy} />
                <Route path={routes.privacyPolicy} exact component={PrivacyPolicy} />
                <Route path={routes.login} exact component={Login} />
                <Route path={routes.register} exact component={Registration} />
                <Route path={routes.passwordRemind} exact component={PasswordRemind} />
                <Route path={routes.passwordReset} exact component={PasswordReset} />
                <Route path={routes.confirmRegistration} exact component={RegistrationConfirmation} />
                <Route path={routes.newsArticle} exact component={Article} />
                <Route path={routes.news} exact component={News} />
                <Route path={routes.coaches} exact component={Coaches} />
                <Redirect to={routes.homepage} />
              </Switch>
            )}
          </Suspense>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isInitCompleted: state.auth.isInitCompleted,
  languageLoading: state.language.languageLoading,
  language: state.language.language,
  isCurrentUserLoading: state.user.currentUserLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onLanguageInit: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
